import React, {useEffect} from "react";
import {Button} from "antd";
import { useHistory } from "react-router-dom";


export const Impressum: React.FC = () => {
  const history = useHistory()

  const backHome = () => {
    history.push("/")
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='impressum'>

      <Button onClick={backHome}>
        Zurück zum Inhalt
      </Button>

      <hr />
      <h1>Impressum</h1>
      <p>Angaben gemäß § 5 TMG</p>
      <p>Viviane Hauschildt <br/>
        Eichelhäherkamp 2<br/>
        21224 Rosengarten <br />
      </p>
      <p>
        <strong>Vertreten durch: </strong><br />
        Viviane Hauschildt<br />
      </p>
      <p>
        <strong>Kontakt:</strong><br />
        Telefon: 0049-17624837030<br />
        E-Mail: <a href='mailto:viviane.h@uschildt.de'>viviane.h@uschildt.de</a><br />
      </p>
      <p>
        <strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong><br />
        Viviane Hauschildt <br />
        Eichelhäherkamp 2<br />
        21224 Rosengarten <br />
      </p>
      <p>
        <strong>Haftungsausschluss: </strong><br />
        <br />
        <strong>Haftung für Inhalte</strong>
        <br />
        <br />
        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
        Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG
        für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
        als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
        oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung
        oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
        diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
        Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
        entfernen.<br /><br /><strong>Urheberrecht</strong><br /><br />
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
        Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
        Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
        dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
        Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
        wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
        umgehend entfernen.
      </p><br />
      <span>
        Impressum vom <a href="https://www.impressum-generator.de">Impressum Generator</a> der <a
        href="https://www.kanzlei-hasselbach.de/">Kanzlei Hasselbach, Rechtsanwälte für Arbeitsrecht und Familienrecht</a>
      </span>
    </div>
);
}
